@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

html,
body {
  @apply bg-white dark:bg-gray-900;
  min-height: 100vh;
  user-select: none;
  max-width: 100vw;
  overflow-x: hidden;
}

#__next {
  min-height: 100vh;
}

.ind {
  position: relative;
}

.ind:after {
  content: "";
  position: absolute;
  z-index: 5;
  width: 50px;
  height: 50px;
  right: -25px;
  top: 44%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  @apply bg-brand-500;
}

.stretch-link::after {
  content: "";
  @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
}

p:empty {
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
}

ul li p {
  margin: 0 !important;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

.wasPrice {
  text-decoration: line-through;
  text-decoration-color: #0c9498;
  text-decoration-thickness: 3px;
}

.freePrice {
  color: #0c9498;
  font-weight: bold;
}

/* Accordion styling */

.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(167, 108, 108, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 7px;
  width: 7px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  margin-top: 5px;
  padding: 10px;
  animation: fadein 0.35s ease-in;
}

#headlessui-portal-root {
  z-index: 99999;
  position: relative;
}

.bounce {
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

/* p div {
  background-color: red !important;
} */

p:empty { display: none; }

@tailwind variants;

.gradient-border {
  position: relative;
}

.gradient-border:after {
  content: ' ';
  position: absolute;
  top: calc(-1 * 3px);
  left: calc(-1 * 3px);
  height: calc(100% + 3px * 2);
  width: calc(100% + 3px * 2);
  background: linear-gradient(60deg, transparent, #27b1b3, transparent, transparent, #27b1b3, transparent);
  border-radius: 6px;
  z-index: 0;
  animation: animatedgradient 2s ease alternate infinite;
  background-size: 200% 200%;
}

@keyframes animatedgradient {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
  /* 100% { background-position: 0% 50%; } */
}


.btn-white:focus {
  @apply outline-none ring-2 ring-offset-2 ring-brand-700;
}

.btn-white {
  @apply w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white transition-all duration-75;
}

.btn-white:hover {
  @apply bg-gray-50;
}

.btn-white[disabled] {
  opacity: 0.5;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}



/* Basic editor styles */
.tiptap {
  @apply p-6 bg-white flex-1 text-slate-700 border border-slate-300 rounded;
}

.tiptap.readonly {
  @apply p-0 min-h-0;
}

.tiptap > * + * {
  margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
}

.tiptap ul {
  list-style-type: disc;
}

.tiptap ol {
  list-style-type: decimal;
}

.tiptap li {
  margin: 0.25em 0;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

.tiptap h1 {
  @apply text-3xl font-bold;
}

.tiptap h2 {
  @apply text-2xl font-bold;
}

.tiptap h3 {
  @apply text-xl font-bold;
}

.tiptap h4 {
  @apply text-lg font-bold;
}

.tiptap h5 {
  @apply text-base font-bold;
}

.tiptap h6 {
  @apply text-sm font-bold;
}

.tiptap code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.tiptap pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.tiptap img {
  max-width: 100%;
  height: auto;
}

.tiptap blockquote {
  padding-left: 1rem;
  border-left: 2px solid #0d0d0d;
}

.tiptap hr {
  border: none;
  border-top: 2px solid #0d0d0d;
  margin: 2rem 0;
}

.items {
  @apply flex flex-col relative overflow-hidden text-sm shadow rounded bg-white text-slate-800 text-left items-start;
}

.tiptap .item {
  @apply bg-transparent border text-slate-800 rounded block m-0 p-1 text-left w-full;
}

.tiptap .item.is-selected {
  border-color: #000;
}

.tiptap:focus {
  outline: none;
}

.is-active {
  @apply bg-brand-600 text-white rounded;
}

.mention {
  @apply text-brand-700 py-[0.1rem] px-[0.3rem];
}

.tiptap ul[data-type='taskList'] {
  list-style-type: none;
  padding: 0;
}

.tiptap ul[data-type='taskList'] li {
  @apply flex items-center space-x-3;
}

.tiptap ul[data-type='taskList'] li input {
  @apply h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600;
}

.tiptap .is-editor-empty:first-child:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.editor-container > div:last-of-type {
  @apply flex flex-col flex-1 overflow-y-scroll h-full;
}

.tiptap p > a {
  @apply text-brand-600 underline cursor-pointer;
}

.notification .tiptap h1,
.notification .tiptap h2,
.notification .tiptap h3,
.notification .tiptap h4 {
  @apply text-base;
}

.notification .tiptap h1,
.notification .tiptap h2,
.notification .tiptap h3,
.notification .tiptap h4,
.notification .tiptap h5,
.notification .tiptap h6 {
  @apply font-semibold;
}

.notification .tiptap {
  @apply text-sm;
}

/* Disable image interaction to make image downloading harder. */
img {
  pointer-events: none;
}

.scrolling {
  animation: marquee 20s linear infinite;
  min-width: 100%;
  white-space: nowrap;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to { 
    transform: translateX(-100%);
  }
}

.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-docker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
  z-index: 1;
}

.video-content {
  z-index: 2;
}